<template>
  <div class="bg-gray-100 p-2">
    <div class="grid justify-items-center">
      <h1 class="text-3xl font-bold text-indigo-700 leading-loose font-sans">
        Lost in the cyberspace?
      </h1>
      <div>
        <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold m-2 p-2 px-4 rounded-md">
          <router-link to="/">
            Come back into the fold!
          </router-link>
        </button>

      </div>
    </div>

  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>